<template>
  <overlay
    :show="loading"
    opacity="0.86"
  >
    <overlay :show="loadingPersistence">
      <page-header
        screen-name="Editar adesão nacional"
        :link-items="linkItems"
      />

      <div
        id="adesao-nacional-edit"
      >
        <main class="card p-2">
          <Filters
            :disable-hr="true"
            @actionSearch="handleSubmitFormFilters"
            @actionClear="clearFilters(); handleSubmitFormFilters();"
          >
            <validation-observer ref="formFilters">
              <b-form>
                <b-row class="align-items-center">
                  <b-col
                    sm="12"
                    lg="6"
                  >
                    <b-form-group
                      label="Nome da empresa"
                      label-for="companyName"
                      label-class="font_size_label"
                    >
                      <b-form-input
                        id="companyName"
                        v-model="search.companyName"
                        maxlength="100"
                        placeholder="Nome fantasia da empresa"
                        autocomplete="off"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    sm="12"
                    lg="6"
                  >
                    <b-form-group
                      label="CNPJ e CPF"
                      label-for="cpfCnpj"
                      label-class="font_size_label"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="CNPJ e CPF"
                        rules="cpfCnpj"
                      >
                        <the-mask
                          id="cpfCnpj"
                          ref="inputMask"
                          v-model="search.companyCpfCnpj"
                          class="form-control"
                          :mask="['###.###.###-##', '##.###.###/####-##']"
                          placeholder="00.000.000/0000-00"
                          autocomplete="off"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </Filters>

          <section class="p-2 bg-custom-blue rounded-top shadow">
            <b-row class="titles">
              <b-col>
                <b-row>
                  <b-col>
                    <h2 class="text-light">
                      Grupo: {{ groupCompany.descricao_grupo }}
                    </h2>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <h4 class="text-light">
                      Operador: {{ groupCompany.sigla_operador }}
                    </h4>
                  </b-col>
                </b-row>
              </b-col>
              <b-col class="text-right lh-4 pr-4">
                <span class="text-light">
                  Doses distribuídas:
                  <strong class="text-light font-medium-3">
                    {{
                      distribuicao.totalDoses.totalGrupo
                        ? distribuicao.totalDoses.totalGrupo
                        : '0' }} |
                  </strong>
                </span>
                <span class="text-light">
                  Doses disponíveis:
                  <strong
                    v-if="groupCompany.totalDosesDisponiveis-distribuicao.totalDoses.totalGrupo < 0"
                    class="text-danger font-medium-3"
                  >
                    {{
                      isNaN(groupCompany.totalDosesDisponiveis-distribuicao.totalDoses.totalGrupo)
                        ? '0'
                        : groupCompany.totalDosesDisponiveis-distribuicao.totalDoses.totalGrupo
                    }}
                  </strong>
                  <strong
                    v-else
                    class="text-light font-medium-3"
                  >
                    {{
                      isNaN(groupCompany.totalDosesDisponiveis-distribuicao.totalDoses.totalGrupo)
                        ? '0'
                        : groupCompany.totalDosesDisponiveis-distribuicao.totalDoses.totalGrupo
                    }}
                  </strong>
                </span>
              </b-col>
            </b-row>
          </section>

          <section class="mt-2 mb-3 ml-1">
            <b-row class="titles">
              <span class="text-custom-blue"><b>Última atualização:</b> {{ groupCompany.ultima_alteracao }} | <b>Atualizado por:</b> {{ groupCompany.nome_pessoa_alteracao }}</span>
            </b-row>
          </section>

          <section
            v-if="noCompaniesInFilters"
            class="text-center mx-2 mt-5"
          >
            <p class="table-empty">
              Não há dados a serem exibidos.
            </p>
          </section>

          <FormDistribuicaoEmpresasEnderecos
            v-if="!loading"
            :empresas="getCompanies"
            :vacinas="groupCompany.estimativa"
            action="edit"
            @setCompaniesInFilters="setCompaniesInFilters"
            @clearFilters="clearFilters(); handleSubmitFormFilters();"
            @setLoading="setLoading"
          />
        </main>
      </div>
    </overlay>
  </overlay>

</template>
<script>
import {
  BRow,
  BCol, BFormInput, BForm, BFormGroup,
} from 'bootstrap-vue'
import { setIdCompanyGroup, setIdCampaignEditAndShow } from '@core/utils/store/setStore'
import { getIdCompanyGroup, getIdCampaignEditAndShow } from '@core/utils/store/getStore'
import moment from 'moment'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { cpfCnpj } from '@validations'
import { TheMask, mask } from 'vue-the-mask'
import { spaceClear, strClear, stringNormalizeClear } from '@core/utils/utils'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import SyringeIcon from '@/assets/images/icons/syringe.svg'
import LocationPinIcon from '@/assets/images/icons/locationPin.svg'
import Overlay from '@/views/components/custom/overlay/Overlay.vue'
import FormDistribuicaoEmpresasEnderecos from './Forms/FormDistribuicaoEmpresasEnderecos.vue'
import {
  confirmOkActionTriangleWarn,
  warningInfoMessage,
  warningMessage,
} from '@/libs/sweetalerts'

import {
  AdesaoUpdateDoseCompany,
  SetAddressCompanyAdesaoNacional,
  GetDistribuicaoDosesAdesaoNacional,
  SetChooseCampaignAdesaoNacional,
  SetChooseCompanyAdesaoNacional,
  SetChooseOperadorAdesaoNacional,
  SetAdesoesEnderecoEmpresa,
} from '@/views/custom-pages/adesao-nacional/helpers'
import {
  getAdesaoNacionalGrupoOperadorCampanha,
  getCompaniesAddressesGroup,
} from '@/views/custom-pages/adesao-nacional/requests'
import Filters from '@/views/components/custom/filters/Filters.vue'

export default {
  title: 'Editar adesão nacional',

  components: {
    BFormGroup,
    BForm,
    ValidationObserver,
    ValidationProvider,
    Filters,
    BFormInput,
    Overlay,
    PageHeader,
    TheMask,
    BRow,
    BCol,
    FormDistribuicaoEmpresasEnderecos,
  },

  directives: {
    mask,
  },

  data() {
    return {
      cpfCnpj,

      search: {
        companyName: '',
        companyCpfCnpj: '',
      },

      SyringeIcon,
      LocationPinIcon,
      moment,
      linkItems: [
        {
          name: 'Adesão Nacional',
          active: true,
          routeName: 'listar-adesao-nacional',
        },
        {
          name: 'Editar',
          active: true,
        },
      ],
      groupCompany: {
        empresas: [],
        estimativa: null,
        descricao_grupo: '...',
        sigla_operador: '...',
        saldo_estimativa: '...',
        ultima_alteracao: '...',
        nome_pessoa_alteracao: '...',
        adesoes: [],
      },

      companiesInOperatorGroup: [],

      loading: true,
      loadingPersistence: false,
      distribuicao: {
        totalDoses: {
          totalGrupo: 0,
        },
      },
    }
  },

  computed: {
    getItemInStore() {
      return this.$store.getters['adesaoNacionalViewState/getPurchaseUpdate']
    },

    getCompanies() {
      return this.groupCompany.empresas
    },

    noCompaniesInFilters() {
      return this.getCompanies.length === 0
    },
  },

  watch: {
    'groupCompany.empresas': {
      handler() {},
      deep: true,
    },
    'groupCompany.estimativa': {
      handler() {},
      deep: true,
    },
  },

  beforeMount() {
    SetAddressCompanyAdesaoNacional(null)
    SetAdesoesEnderecoEmpresa(null)
    this.distribuicao = GetDistribuicaoDosesAdesaoNacional()
  },

  mounted() {
    if (!this.getItemInStore) {
      this.redirectToMainPage()

      return false
    }

    setIdCompanyGroup(this.getItemInStore.id_grupo_operador)
    setIdCampaignEditAndShow(this.getItemInStore.id_campanha)

    return this.prepareFormAdesaoNacional()
  },

  methods: {
    handleSubmitFormFilters() {
      this.groupCompany.empresas = this.companiesInOperatorGroup

      if (!this.search.companyName && !this.search.companyCpfCnpj) {
        this.clearFilters()
      }

      this.groupCompany.empresas = this.groupCompany.empresas.filter(item => {
        const companyName = stringNormalizeClear(spaceClear(item.nome_empresa))
        const searchCompanyName = stringNormalizeClear(spaceClear(this.search.companyName))

        const matchNome = this.search.companyName
          ? companyName.toLowerCase().includes(searchCompanyName.toLowerCase())
          : true

        const matchCpfCnpj = strClear(this.search.companyCpfCnpj)
          ? item.cpf_cnpj_empresa.includes(strClear(this.search.companyCpfCnpj))
          : true

        return matchNome && matchCpfCnpj
      })

      return true
    },

    setCompaniesInFilters() {
      const { companiesInOperatorGroup } = this

      return new Promise((resolve, reject) => {
        try {
          const diff = companiesInOperatorGroup.filter(item => !this.groupCompany.empresas.some(item1 => item1.id_empresa === item.id_empresa))

          diff.forEach(item => this.groupCompany.empresas.push(item))

          resolve(diff.length)
        } catch (error) {
          reject(error)
        }
      })
    },

    clearFilters() {
      this.$refs.inputMask.lastValue = ''
      this.$refs.inputMask.display = ''

      this.search = {
        companyName: '',
        companyCpfCnpj: '',
      }
    },

    async loadDataAdhesion() {
      this.loading = true

      const idGrupoOperador = this.getItemInStore.id_grupo_operador
      const idCampanha = this.getItemInStore.id_campanha

      await getAdesaoNacionalGrupoOperadorCampanha({ idGrupoOperador, idCampanha })
        .then(({ data }) => {
          if (data.empresas_adesoes.length === 0) {
            confirmOkActionTriangleWarn(
              'Não foram encontradas adesões para este grupo operador e campanha.',
            )
              .then(() => {
                this.redirectToMainPage()
              })

            return false
          }

          this.loading = false

          return this.fillData(data)
        })
        .catch(error => {
          this.handleError(error.response)
        })
    },

    fillData(data) {
      this.groupCompany.descricao_grupo = data.descricao_grupo
      this.groupCompany.sigla_operador = data.sigla_dr_operador
      this.groupCompany.totalDosesDisponiveis = data.doses_disponiveis_grupo_operador + data.doses_distribuidas
      this.groupCompany.ultima_alteracao = moment(data.ultima_atualizacao).format('DD/MM/YYYY, HH:mm')
      this.groupCompany.nome_pessoa_alteracao = data.usuario_alterador.nome
      this.prepareAdesoes(data.empresas_adesoes)

      SetChooseCampaignAdesaoNacional({ id_campanha: data.id_campanha })
      SetChooseCompanyAdesaoNacional({ id_grupo: data.id_grupo })
      SetChooseOperadorAdesaoNacional({ id_grupo_operador: data.id_grupo_operador })

      this.companiesInOperatorGroup = this.groupCompany.empresas
    },

    prepareAdesoes(adesoes) {
      const adesoesEndereco = {}

      adesoes.forEach(empresa => {
        adesoesEndereco[empresa.id_empresa] = []

        if (Object.keys(empresa.adesoes).length > 0) {
          Object.keys(empresa.adesoes).forEach(adesaoId => {
            const adesao = empresa.adesoes[adesaoId]

            adesoesEndereco[empresa.id_empresa][adesao.id_empresa_endereco] = {
              idAdesao: adesao.id_adesao,
              unidade: adesao.unidade.descricao,
              vacinas: {},
            }

            adesao.adesao_vacina.forEach(vacina => {
              adesoesEndereco[empresa.id_empresa][adesao.id_empresa_endereco].vacinas[vacina.id_vacina] = {
                saldo: vacina.quantidade,
                idAdesaoVacina: vacina.id_adesao_vacina,
              }

              AdesaoUpdateDoseCompany(adesao.id_empresa_endereco, empresa.id_empresa, vacina.id_vacina, vacina.quantidade)
            })
          })
        }
      })

      SetAdesoesEnderecoEmpresa(adesoesEndereco)
    },

    redirectToList() {
      this.$router.push(
        {
          name: 'listar-adesao-nacional',
        },
      )
    },

    async getEstimativaCampanhaNacionalGroup() {
      await this.$http.get(
        this.$api.estimativaCampanhaNacionalGroup(getIdCampaignEditAndShow(), getIdCompanyGroup()),
      )
        .then(({ data }) => {
          this.groupCompany.estimativa = data
        })
        .catch(error => {
          this.handleError(error.response)
        })
    },

    async getEnderecosEmpresasGroup() {
      const params = { idGrupoOperador: getIdCompanyGroup() }

      await getCompaniesAddressesGroup(params)
        .then(({ data }) => {
          this.groupCompany.empresas = data.empresa
        })
        .catch(error => {
          this.handleError(error.response)
        })
    },

    async prepareFormAdesaoNacional() {
      this.loading = true

      await this.getEstimativaCampanhaNacionalGroup()
      await this.getEnderecosEmpresasGroup()

      SetAddressCompanyAdesaoNacional(this.groupCompany.empresas)

      await this.loadDataAdhesion(getIdCompanyGroup(), getIdCampaignEditAndShow())
    },

    setLoading(loading) {
      this.loadingPersistence = loading
    },

    redirectToMainPage() {
      this.$store.commit('adesaoNacionalViewState/setPurchaseUpdate', null)
      this.$router.replace({ name: 'listar-adesao-nacional' })
    },

    handleError(response) {
      const errors = response.status === 400 || response.status === 404

      if (errors) {
        warningInfoMessage(
          response.data.errors,
          'Confira os dados e depois salve a adesão.',
          'Entendido',
        )

        return
      }

      warningMessage(
        'Não foi possível realizar a sua solicitação.<br /> Entre em contato com o suporte.',
      )
    },
  },
}
</script>

<style lang="scss">
#adesao-nacional-edit {
  .shadow {
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
  }
  .icon-location {
    max-width: 50px;
  }
  .content-distribuicao {
    .card-header {
      background: #e5f0f9;
      border-left: 10px solid #2772C0;
      border-radius: 0;
    }
  }
  .lh-4 {
    line-height: 4rem;
  }
}
</style>
